import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import React from 'react'
import { InsightsPathsEnum } from '../public/static/insights-paths.enum'
import { LocaleNamespaces } from '../public/static/locale-namespaces.enum'
import { Insights } from '../src/components/pages/Insights'
import { InternationalLandingPage } from '../src/components/pages/InternationalLandingPage'
import { IInsightProps } from '../src/interfaces/InsightsInterface'
import { PageContext } from '../src/interfaces/PageContext'
import DecoAPIService, { DecoAPISectionsIDS } from '../src/services/deco/DecoAPIService'
import WordpressPersonoService, { IPostRequestParams } from '../src/services/WordpressPersonoService'

const Home = ({ page, numberOfPages, gridPosts, language, slug, urlPathName, shouldShowPromotionalBanner, carousel, decoFooterProps, decoHeaderProps }: IInsightProps) => {
  if (language == 'en') {
    return <InternationalLandingPage />
  }

  return (
    <Insights
      numberOfPages={numberOfPages}
      page={page}
      gridPosts={gridPosts}
      language={language}
      slug={slug}
      urlPathName={urlPathName}
      carousel={carousel}
      shouldShowPromotionalBanner={shouldShowPromotionalBanner}
      decoFooterProps={decoFooterProps}
      decoHeaderProps={decoHeaderProps}
    />
  )
}

export const getServerSideProps = async (ctx: PageContext) => {
  const decoApiService = new DecoAPIService()
  const items = await decoApiService.getInfos({ sectionsIds: [DecoAPISectionsIDS.FOOTER, DecoAPISectionsIDS.HEADER] })

  const nullInsightsProps: IInsightProps = {
    numberOfPages: 1,
    page: null,
    higlightPost: null,
    gridPosts: null,
    language: ctx.locale,
    slug: '',
    urlPathName: InsightsPathsEnum.DEFAULT,
    carousel: null,
    shouldShowPromotionalBanner: false,
    decoFooterProps: items[DecoAPISectionsIDS.FOOTER],
    decoHeaderProps: items[DecoAPISectionsIDS.HEADER],
  }

  if (ctx.locale == 'en') {
    return {
      props: {
        ...nullInsightsProps,
        ...(await serverSideTranslations(ctx.locale, [
          LocaleNamespaces.HEADER,
          LocaleNamespaces.NOTIFY_ME_MODAL,
          LocaleNamespaces.LEAD,
          LocaleNamespaces.FOOTER,
          LocaleNamespaces.ERROR,
          LocaleNamespaces.ABOUT,
          LocaleNamespaces.INTERNATIONAL_LANDING_PAGE,
        ])
        ),
      }
    }
  }

  const wordpressPersonoService = new WordpressPersonoService(ctx.locale)

  const params: IPostRequestParams = {
    offset: 0,
    perPage: 9
  }

  const response = await wordpressPersonoService.getPosts(params)
  const carouselPosts = await wordpressPersonoService.getCarouselPosts()

  const numberOfPages = Math.ceil(response.headers['x-wp-total'] / 9)
  const allPosts = response.data

  if (!allPosts || !carouselPosts) {

    return {
      props: {
        ...nullInsightsProps,
        ...(await serverSideTranslations(ctx.locale, [
          LocaleNamespaces.HEADER,
          LocaleNamespaces.LEAD,
          LocaleNamespaces.FOOTER,
          LocaleNamespaces.ERROR,
          LocaleNamespaces.INSIGHTS
        ])
        ),
      }
    }
  }

  const posts = allPosts.map(currentPost => currentPost.data)
  const gridPosts = posts.filter(post => !carouselPosts.includes(post))

  const insightsProps: IInsightProps = {
    numberOfPages,
    page: null,
    gridPosts,
    language: ctx.locale,
    slug: '',
    urlPathName: InsightsPathsEnum.DEFAULT,
    carousel: carouselPosts,
    shouldShowPromotionalBanner: true,
    decoFooterProps: items[DecoAPISectionsIDS.FOOTER],
    decoHeaderProps: items[DecoAPISectionsIDS.HEADER],
  }

  return {
    props: {
      ...insightsProps,
      ...(await serverSideTranslations(ctx.locale, [
        LocaleNamespaces.HEADER,
        LocaleNamespaces.LEAD,
        LocaleNamespaces.FOOTER,
        LocaleNamespaces.ERROR,
        LocaleNamespaces.INSIGHTS
      ])
      ),
    }
  }
}

export default Home